@font-face {
  font-family: 'proxima';
  src: url('./fonts/Proxima-Nova-Font.otf');
}

body {
  margin: 0;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}
